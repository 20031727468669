@import '~common/ui_imports';

.linkButton {
  background-color: var(--ux-white);
  border: 1px solid var(--ux-gray-400);
  border-radius: var(--ux-border-radius);
  color: inherit;
  display: block;
  height: 375px;
  padding: 40px;
  text-align: center;
  text-decoration: none;
  transition: top ease 0.2s;
  width: 350px;
  user-select: none;

  @include media-breakpoint-up(lg) {
    height: 380px;
    width: 400px;
  }

  img {
    height: 180px;
    margin-top: 1.875rem;
    width: 180px;;
  }

  &:hover {
    background-color: var(--synth-header-neutral);
    color: inherit;
    text-decoration: none;
    top: -0.625rem;
  }
}

.text {
  @include font-type-30;
  color: var(--synth-text-neutral);
  margin-bottom: 0;
}

.title {
  @include font-type-50--bold;
  color: var(--synth-text-neutral);
  margin-bottom: var(--synth-spacing-6);
}
