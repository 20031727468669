@import '~common/ui_imports';

.root {
  font-family: $body-fonts;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 2rem 0;

  @include media-breakpoint-up(md) {
    padding: 3rem 0;
  }
}

.header {
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }
}

.heading {
  margin-bottom: var(--synth-spacing-8);
}

.linkButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > *:first-of-type {
    margin: 0 0 var(--synth-spacing-6);
  }
  
  > *:nth-of-type(1) {
    order: 2; // Researcher
    margin: 0 0 var(--synth-spacing-6);
  }

  > *:nth-of-type(2) {
    order: 1; // Participant
    margin: 0 0 var(--synth-spacing-6);
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    > *:first-of-type {
      margin: 0 var(--synth-spacing-6) 0 0;
    }

    > *:nth-of-type(1) {
      order: 1; // Researcher back to first
      margin: 0 var(--synth-spacing-6) var(--synth-spacing-6) 0;
    }

    > *:nth-of-type(2) {
      order: 2; // Participant back to second
    }
  }
}
